@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://cdn.rawgit.com/mfd/f3d96ec7f0e8f034cc22ea73b3797b59/raw/856f1dbb8d807aabceb80b6d4f94b464df461b3e/gotham.css");

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.banner-section {
  background: url("./assets/GREEN BG copy1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.feature-section {
  background: url("./assets/feature-bgwith-svg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -70px;
  border-radius: 50px;
  z-index: 1;
}

.contact-section {
  background: url("./assets/GREEN BG copy1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -65px;
  margin-bottom: -65px;
  z-index: 0;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #fff;
  opacity: 0.7;
}
.contact-form input[type="number"]::-webkit-outer-spin-button,
.contact-form input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.contact-form textarea {
  height: unset;
  resize: none;
}

.login-section {
  background: url("./assets/GREEN BG copy1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.login-section .input::placeholder {
  color: white;
}
.review-form textarea {
  /* height: unset; */
  resize: none;
}
.faq-section textarea {
  resize: none;
}

.glassy-bg {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2)
  );
  backdrop-filter: blur(50px);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
}
.glassy-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("./assets/faqs-bg.png") right/contain no-repeat;
  filter: blur(12px);
  z-index: -1;
}

.footer-section {
  background: url("./assets/feature-bgwith-svg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.whatsapp-icon {
  width: 100px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
}

.whatsapp-icon:hover {
  width: 250px;
}

.whatsapp-icon span {
  opacity: 0;
  transition: opacity 0.3s ease-out;
  display: none;
}

.whatsapp-icon:hover span {
  opacity: 1;
  display: block;
  margin-right: 15px;
  white-space: nowrap;
  transition: opacity 0.3s ease-out;
}

/* swiper styles */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
  padding: 40px 0 112px 0;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 30px;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

/* animations */
.wiggle-img {
  animation-name: float-bob-y;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.wiggle-img2 {
  animation-name: float-bob-yy;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.wiggle-img3 {
  animation-name: float-bob-yyy;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.wiggle-img-mobile {
  animation-name: float-bob-mobile;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes float-bob-y {
  0% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
  50% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
  }
  100% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
}
@keyframes float-bob-yy {
  0% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
  }
  50% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
  100% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
  }
}
@keyframes float-bob-yyy {
  0% {
    transform: rotate(-20deg);
    -webkit-transform: rotate(-20deg);
  }
  50% {
    transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
  }
  100% {
    transform: rotate(-20deg);
    -webkit-transform: rotate(-20deg);
  }
}
/* @keyframes float-bob-mobile {
  0% {
    transform: translate3d(-10px, 20px, -10px);
    -webkit-transform: translate3d(-10px, 20px, -10px);
  }
  50% {
    transform: translate3d(-20px, 20px, -20px);
    -webkit-transform: translate3d(-20px, 20px, -20px);
  }
  100% {
    transform: translate3d(-10px, 20px, -10px);
    -webkit-transform: translate3d(-10px, 20px, -10px);
  }
} */
/* @keyframes float-bob-mobile {
  0% {
    transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(10px, 20px, 0);
  }
  50% {
    transform: translate3d(20px, 0, 0);
  }
  75% {
    transform: translate3d(10px, -20px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
} */
@keyframes float-bob-mobile {
  0% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  20% {
    transform: translate3d(-15px, 10px, 0) rotate(-5deg);
  }
  40% {
    transform: translate3d(15px, -10px, 0) rotate(5deg);
  }
  60% {
    transform: translate3d(-10px, -15px, 0) rotate(-3deg);
  }
  80% {
    transform: translate3d(10px, 15px, 0) rotate(3deg);
  }
  100% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
}

/* feature card animations */
@keyframes feature-anim {
  0%,
  100% {
    transform: translateX(0) scale(1.1);
  }
  25% {
    transform: translateX(-5px) scale(1.15);
  }
  50% {
    transform: translateX(5px) scale(1.1);
  }
  75% {
    transform: translateX(-5px) scale(1.15);
  }
}

.feature-card img {
  transition: transform 0.8s cubic-bezier(0.25, 1, 0.5, 1), filter 0.5s ease;
  will-change: transform;
}

.feature-card:hover img {
  animation: feature-anim 2s ease-in-out infinite;
  transform: scale(1.1);
  /* filter: brightness(0.7); */
}

@keyframes typing {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.name-letter {
  display: inline-block;
  opacity: 0;
  animation: typing 0.5s forwards;
}

.name-letter:nth-child(1) {
  animation-delay: 0.1s;
}
.name-letter:nth-child(2) {
  animation-delay: 0.2s;
}
.name-letter:nth-child(3) {
  animation-delay: 0.3s;
}
.name-letter:nth-child(4) {
  animation-delay: 0.4s;
}
.name-letter:nth-child(5) {
  animation-delay: 0.5s;
}
.name-letter:nth-child(6) {
  animation-delay: 0.6s;
}
.name-letter:nth-child(7) {
  animation-delay: 0.7s;
}

/* @keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  animation: fadeUp 1s ease-out forwards;
} */

@media only screen and (min-width: 769px) {
  .swiper-slide:first-child {
    transition: transform 100ms;
  }

  .swiper-slide:first-child img {
    transition: box-shadow 500ms;
  }

  .swiper-slide.swiper-slide-active:first-child {
    z-index: 2;
    margin-left: 8%;
  }

  .swiper-slide:nth-child(2) {
    transition: transform 100ms;
  }

  .swiper[dir="rtl"] .swiper-slide.swiper-slide-active:first-child {
    transform: translateX(-50%);
  }

  .swiper[dir="rtl"] .swiper-slide.swiper-slide-next:nth-child(2) {
    transform: translateX(-55%);
  }
}

@media (max-width: 640px) {
  .feature-section {
    border-radius: 45px;
  }
}

@media (max-width: 480px) and (min-width: 420px) {
  .banner1-img {
    left: 14%;
    top: 33%;
  }
  .banner2-img {
    right: 20%;
    top: 42%;
  }
  .banner3-img {
    left: 25%;
    top: 65%;
  }
}
@media (max-width: 380px) {
  .banner1-img {
    left: 16%;
    top: 33%;
  }
  .banner2-img {
    right: 15%;
    top: 42%;
  }
  .banner3-img {
    left: 22%;
    top: 64%;
  }
}
